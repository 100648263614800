import React from 'react';

function App() {
  const value = 'Sohin Shah, MS';
  const link = 'https://www.linkedin.com/in/ssohin/';
  return <div>
    <h1>{value}</h1>
    <a href={link} target="_blank" rel="noopener noreferrer">LinkedIn</a>
  </div>;
}

export default App;
